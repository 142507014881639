export const react_domain = `${window.location.origin}`;

// // LOCAL PANEL
// export const broker_backend = `http:/localhost:8000/signal/broker-signals`;
// export const base_url = `http://localhost:7700/`;
// export const broker_redirect_url = "https://connectbox.tradestreet.in/backend/";
// export const socket_Url = `https://connectbox.tradestreet.in:1001`;


// // CONNECT BOX LIVE PANEL
export const broker_redirect_url = "https://connectbox.tradestreet.in/backend/";
export const socket_Url = `https://connectbox.tradestreet.in:1001`;
export const broker_backend = `https://connectbox.tradestreet.in/signal/broker-signals`;
export const base_url = `https://connectbox.tradestreet.in/backend/`;


// THE ALGO CONNECTBOX LIVE
// export const broker_redirect_url = "https://connect.thealgo.services/backend/";
// export const socket_Url = `https://connect.thealgo.services:1001`;
// export const broker_backend = `https://connect.thealgo.services/signal/broker-signals`;
// export const base_url = `https://connect.thealgo.services/backend/`;


// COMMAN BUILD
// export const broker_redirect_url = react_domain+"/backend/";
// export const socket_Url = `${react_domain}:1001`;
// export const broker_backend = react_domain+`/signal/broker-signals`;
// export const base_url = react_domain+`/backend/`;


// console.log("broker_redirect_url",broker_redirect_url);
// console.log("socket_Url",socket_Url);
// console.log("broker_backend",broker_backend);
// console.log("base_url",base_url);

